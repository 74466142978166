<template>
  <div class="p-8">
    <div class="flex justify-between gap-2">
      <div class="flex gap-2 items-center">
        <font-awesome-icon icon="clipboard-list" style="width: 25px; height: 25px;color: #1C3FAA"/>
        <span class="text-base font-bold" style="color: #1C3FAA">Legalizar Egreso</span>
      </div>
      <back-button/>
    </div>
    <div class="flex gap-6 mt-8">
      <span><span class="font-bold">Fecha: </span>2022-02-02</span>
      <span><span class="font-bold">Solicitante: </span>MARIA CAMILA TARAZONA TARAZONA</span>
      <span><span class="font-bold">Valor: </span>$ 150.000,00</span>
      <span><span class="font-bold">Concepto: </span>REEMBOLSO CAJA MENOR TOLEDO</span>
    </div>
  </div>
  <div class="bg-yellow-50 p-8 flex justify-between">
    <div class="flex-row" style="width: 400px">
      <span class="text-2xl font-bold" style="color: #1C3FAA">Valor Legalizado: $ 567.400,00</span>
      <div class="flex justify-between">
        <span>Valor Sobrante: $100.000,00</span>
        <span>Valor Faltante: $100.000,00</span>
      </div>
    </div>
    <div>
      <Button label="Entregar Faltante"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'header'
}
</script>

<style scoped>

</style>
