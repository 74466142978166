<template>
  <div class="h-10">
    <h1 class="font-bold text-lg capitalize">Caja</h1>
  </div>
  <div class="box">
    <Header />
    <Table />
  </div>
</template>

<script>
import Header from '../../components/legalizeExpenses/header'
import Table from '../../components/legalizeExpenses/table'

export default {
  name: 'legalizeExpense',
  components: {
    Header,
    Table
  }
}
</script>

<style scoped>

</style>
