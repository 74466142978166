<template>
  <div class="p-8">
    <div class="flex justify-between">
      <div class="flex-row" style="width: 150px">
        <span>Tipo de Factura</span>
        <Dropdown v-model="selectedCity" :options="cities" optionLabel="name" placeholder="Seleccione una opcion" />
      </div>
      <div class="flex items-center gap-6">
        <span>Total legalizado: $500.000,00</span>
        <span>Total registros: 3</span>
        <Button label="Agregar factura" icon="pi pi-plus" @click="$router.push({name: 'pharmasan.contabilidad.caja-menor.ver-caja-menor.egresos.legalizar.agregar'})" />
      </div>
    </div>
    <DataTable :value="products" responsiveLayout="scroll" class="mt-6 p-datatable-sm">
      <template #empty>
        <span>No hay datos para mostrar</span>
      </template>
      <Column field="code" header="Fecha"></Column>
      <Column field="name" header="Tipo Factura"></Column>
      <Column field="category" header="N° Factura"></Column>
      <Column field="quantity" header="Proveedor"></Column>
      <Column field="quantity" header="Concepto"></Column>
      <Column field="quantity" header="Total Factura"></Column>
      <Column field="quantity" header="Acciones"></Column>
    </DataTable>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'table',
  setup () {
    const store = useStore()
    const selectedCity = ref(null)
    const cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
    ]
    const storeInvoicesTypes = computed(() => store.getters['storePettyCash/storeInvoicesTypes'])

    onMounted(() => {
      store.dispatch('storeInvoicesTypes')
    })

    return {
      selectedCity,
      cities,
      storeInvoicesTypes
    }
  }
}
</script>

<style scoped>

</style>
